import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { Popup } from "@urbica/react-map-gl";
import { toast } from "react-toastify";
import { useStaticQuery, graphql } from "gatsby";
import ClipLoader from "react-spinners/ClipLoader";
import Select from "react-select";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import Card from "../../components/Cards";
import Map from "../../components/Map";
import Marked from "../../components/Map/Marker";
import Paginate from "../../components/Paginate/Paginate";
import requests from "../../services/requests";
import profile from "../../services/profile";
import CardFull from "../../components/Cards/CardFull";
import ConsultationByPeriod from "../../components/ConsultationByPeriod";
import { useSelector } from "react-redux";
import Modal from "../../components/Modals";
import ModalShowFile from "./components/ModalShow";
import ListCollapse from "./components/ListCollapse";
import { GoSearch } from "react-icons/go";
import DetalhePosicao from "./components/DetalhePosicao";

const TransportPage = () => {
    const [load, setLoad] = useState(false);
    const [list, setList] = useState([]);
    const [submitting, setIsSubmitting] = useState(false);
    const [onClicked, setOnClicked] = useState({});
    const [action, setAction] = useState(1);
    const [focusFreight, setFocusFreight] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [freightSelected, setFreightSelected] = useState([]);
    const searchPeriod = useSelector(state => state.period);
    const [clientsOptions, setClientsOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isLoadingClients, setIsLoadingClients] = useState(false);
    const [positions, setPositions] = useState([]);
    const [noClientOptionsMessage, setNoClientOptionsMessage] = useState("Nenhum resultado encontrado");
    const [meta, setMeta] = useState({
        current_page: 1,
    });
    const [searchCte, setSearchCte] = useState(null);
    const parser = new DOMParser();
    const [complPositions, setComplPositions] = useState({});

    const handleSearchCte = number => {
        setSearchCte(number);
    };

    const loadClientOptions = (search = "") => {
        setNoClientOptionsMessage("Carregando");
        setIsLoadingClients(true);

        if (search.length < 2) {
            setNoClientOptionsMessage("Insira ao menos 2 caracteres para fazer a busca");
            setIsLoadingClients(false);
        } else {
            requests
                .listAllClients(search)
                .then(res => {
                    var aux = [];
                    res.map(client => {
                        return (
                            aux.push({
                                value: client.identifier,
                                label: client.name + " (" + client.documentNumberFormatted + ")",
                            })
                        )
                    });
                    setClientsOptions(aux);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        }
    };

    const actionModal = () => {
        setOpenModal(!openModal);
    };

    // Modal show
    const [currentCteFile, setCurrentCteFile] = useState(null);
    const [openModalShow, setOpenModalShow] = useState(false);
    const actionModalShow = (url, identifier, cteNumber) => {
        const link = {
            url,
            identifier,
            cteNumber,
        };
        setCurrentCteFile(link);
        setOpenModalShow(!openModalShow);
    };
    const closeModalShow = () => {
        setOpenModalShow(false);
    };

    const { location } = useStaticQuery(graphql`
        query {
            location: file(relativePath: { eq: "location.png" }) {
                childImageSharp {
                    fixed(height: 22, width: 16) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `);

    const centroid = coordinates => {
        let latXTotal = 0;
        let latYTotal = 0;
        let lonDegreesTotal = 0;

        if (coordinates.length === 0) {
            return {
                latitude: -14.235004,
                longitude: -51.925282,
            };
        }

        let currentLatLong;
        for (let i = 0; (currentLatLong = coordinates[i]); i++) {
            if (!currentLatLong.latitude || !currentLatLong.longitude) {
                continue;
            }
            const latDegrees = currentLatLong.latitude;
            const lonDegrees = currentLatLong.longitude;
            const latRadians = (Math.PI * latDegrees) / 180;
            latXTotal += Math.cos(latRadians);
            latYTotal += Math.sin(latRadians);

            lonDegreesTotal += lonDegrees;
        }
        const finalLatRadians = Math.atan2(latYTotal, latXTotal);
        const finalLatDegrees = (finalLatRadians * 180) / Math.PI;
        const finalLonDegrees = lonDegreesTotal / coordinates.length;

        return {
            latitude: finalLatDegrees,
            longitude: finalLonDegrees,
        };
    };
    const [viewport, setViewport] = useState({
        latitude: -14.235004,
        longitude: -51.925282,
        zoom: 2,
    });

    const actionCentroid = location => {
        if (location.latitude && location.longitude) {
            setViewport({
                latitude: location.latitude,
                longitude: location.longitude,
                zoom: 12,
            });
        }
    };

    const listFreights = () => {
        setLoad(true);

        var filters = {
            ...searchPeriod,
            client: selectedClient,
            cteNumber: searchCte,
        };

        requests
            .listFreights(filters, meta.current_page > 0 ? meta.current_page : 1)
            .then(data => {
                setList(data.data);
                setMeta(data.meta);

                const coordinates = [];
                for (let i in data.data) {
                    if (data.data[i].lastLatitude && data.data[i].lastLongitude) {
                        coordinates.push({
                            latitude: data.data[i].lastLatitude,
                            longitude: data.data[i].lastLongitude,
                        });
                    }
                }

                const centered = centroid(coordinates);
                setViewport({
                    latitude: centered.latitude,
                    longitude: centered.longitude,
                    zoom: 5,
                });
            });
        setLoad(false);
    };

    const handleClickedPopup = key => {
        let aux = { ...onClicked };
        if (aux && aux[key]) {
            aux[key] = !aux[key];
        } else {
            aux[key] = true;
        }

        setOnClicked(aux);
    };

    //XML
    const handleDownloadXML = identifier => {
        let link = document.createElement("a");

        link.href = (requests.getApiUrl() + `/open/freight/${identifier}/download-cte`).replace("//open", "/open");
        link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    //CTE
    const handleCTEUpload = useCallback((e, identifier) => {
        setIsSubmitting(true);

        const file = e.target.files[0];
        const fileName = file.name;
        const data = new FormData();

        data.append("file_upload[file]", file);

        requests
            .uploadCTE(data, identifier)
            .then(() => {
                toast.success(`${fileName} enviado com sucesso`);
                listFreights();
                setIsSubmitting(false);
            })
            .catch(error => {
                toast.error(`${fileName} falhou no envio, tente novamente`);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }, []);

    const removeCTEFile = useCallback(identifier => {
        setIsSubmitting(true);

        requests
            .removeCTEFile(identifier)
            .then(() => {
                toast.success(`Arquivo removido`);
                listFreights();
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }, []);

    const customStyles = {
        placeholder: () => ({
            color: "#bdc3d0",
            fontFamily: "inherit",
            fontSize: ".9rem",
            fontWeight: "none",
            paddingLeft: ".7rem",
        }),
    };

    useEffect(() => {
        loadClientOptions();
    });

    useEffect(() => {
        listFreights();
    }, [action, searchPeriod, selectedClient, searchCte]);

    return (
        <Layout>
            <SEO title="Transporte" />

            <Modal
                show={openModal}
                onClose={() => {
                    actionModal();
                    setPositions([]);
                }}
                size="xs:w-11/12 lg:w-1/2 min-h-80%"
                title={
                    <div className="flex items-center">
                        <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>
                            {positions && positions.length > 0 ? "Posições" : "Aviso"}
                        </h5>
                    </div>
                }
            >
                <DetalhePosicao entity={freightSelected} positions={positions} setPositions={setPositions} />
            </Modal>

            <ModalShowFile
                actionModal={closeModalShow}
                openModal={openModalShow}
                entity={currentCteFile}
                handleSuccess={() => {
                    closeModalShow();
                }}
            />

            <CardFull className="justify-between flex xs:flex-wrap lg:flex-wrap flex-col-reverse ">
                <div className="flex xs:w-full lg:w-100 items-center mb-2 ">
                    <ConsultationByPeriod />
                    <div className="xs:w-full md:w-1/6 lg:w-1/6 px-1 xs:mb-5 lg:mb-0">
                        <div className="flex items-stretch w-full mb-1 my-1 lg:my-0">
                            <div className="flex -mr-px">
                                <div className="flex items-center leading-normal rounded rounded-r-none border border-r-0 border-C2 px-3">
                                    <GoSearch className="text-roxo_oficial" />
                                </div>
                            </div>
                            <input
                                type="text"
                                id="search"
                                name="search"
                                className="outline-none flex-1 border-t border-r border-b h-10 border-C2 rounded rounded-l-none px-3"
                                placeholder="buscar por CTE"
                                onChange={event => handleSearchCte(event.target.value)}
                            />
                        </div>
                    </div>

                    <div className="xs:w-full md:w-1/2 lg:w-1/4 px-1 xs:mb-5 lg:mb-0">
                        <Select
                            id="client"
                            name="client"
                            styles={customStyles}
                            options={clientsOptions}
                            onChange={item => {
                                setSelectedClient(item.value);
                            }}
                            noOptionsMessage={() => noClientOptionsMessage}
                            onInputChange={inputValue => {
                                loadClientOptions(inputValue);
                            }}
                            isLoading={isLoadingClients}
                            className="outline-none transform-none"
                            placeholder="buscar por cliente"
                        />
                    </div>
                </div>
            </CardFull>

            <div className="p-4">
                <Card>
                    <h5 className="uppercase mb-2">Transporte</h5>
                    <Map viewport={viewport} setViewport={setViewport} style={{ width: "100%", height: "400px" }}>
                        {list &&
                            list.map((entity, key) => {
                                if (!entity || entity.isDelivered || !entity.lastLatitude || !entity.lastLongitude) {
                                    return <></>;
                                }
                                return (
                                    <div key={key}>
                                        <Marked
                                            key={key}
                                            latitude={entity.lastLatitude}
                                            longitude={entity.lastLongitude}
                                            type="car"
                                            color={entity.identifier === focusFreight?.identifier ? "red" : "roxo_oficial"}
                                            onClick={() => handleClickedPopup(entity.identifier)}
                                        />
                                        {onClicked && onClicked[entity.identifier] && (
                                            <Popup
                                                className="p-2"
                                                latitude={entity.lastLatitude}
                                                longitude={entity.lastLongitude}
                                                closeButton={false}>
                                                CTE: {entity.cteNumber}
                                                <br />
                                                Placa: {entity.plates}
                                                <br />
                                                Data: {moment(entity.lastReceivedAt).format("DD/MM/YYYY")}
                                                <br />
                                                Hora: {moment(entity.lastReceivedAt).format("HH:mm")}
                                                <br />
                                            </Popup>
                                        )}
                                    </div>
                                );
                            })}
                    </Map>

                    <div className="overflow-auto">
                        <table className="table-auto mt-10 w-full text-left">
                            <thead className="border-b border-roxo_oficial">
                                <tr>
                                    {/* eslint-disable-next-line */}
                                    <th className="w-1" align="right"></th>
                                    <th className="text-roxo_oficial pr-4">
                                        Data
                                    </th>
                                    <th className="text-roxo_oficial pr-4">
                                        CTE
                                    </th>
                                    <th className="text-roxo_oficial pr-4">
                                        Placa
                                    </th>
                                    <th className="text-roxo_oficial pr-4">
                                        Emitente
                                    </th>
                                    <th className="text-roxo_oficial pr-4">
                                        Expedidor
                                    </th>
                                    <th className="text-roxo_oficial pr-4">
                                        Remetente
                                    </th>
                                    <th className="text-roxo_oficial pr-4">
                                        Destinatário
                                    </th>
                                    <th className="text-roxo_oficial pr-4">
                                        Processos
                                    </th>
                                    <th className="text-roxo_oficial pr-4">
                                        Entrega
                                    </th>
                                    <th className="text-roxo_oficial pr-4">
                                        Frete
                                    </th>
                                    <th className="text-roxo_oficial pt-2 pr-4 pb-2">
                                        Valor Merc.
                                    </th>
                                    <th className="text-roxo_oficial pr-4">
                                        XML
                                    </th>
                                    {profile.role !== "ROLE_CLIENT" && (
                                        <th className="text-roxo_oficial">
                                            Arquivo CTE
                                        </th>
                                    )}
                                    {profile.role !== "ROLE_CLIENT" && (
                                        <th className="text-roxo_oficial" style={{ width: "2%" }}>
                                            Visibilidade
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {!load && (
                                    <>
                                        {list?.map((entity, key) => {
                                            return (
                                                <ListCollapse
                                                    key={key}
                                                    entity={entity}
                                                    open={false}
                                                    setFocusFreight={setFocusFreight}
                                                    actionCentroid={actionCentroid}
                                                    location={location}
                                                    profile={profile}
                                                    setFreightSelected={setFreightSelected}
                                                    actionModal={actionModal}
                                                    handleDownloadXML={handleDownloadXML}
                                                    actionModalShow={actionModalShow}
                                                    removeCTEFile={removeCTEFile}
                                                    submitting={submitting}
                                                    handleCTEUpload={handleCTEUpload}
                                                    parser={parser}
                                                    complPositions={complPositions}
                                                    setComplPositions={setComplPositions}
                                                    listFreights={listFreights}
                                                />
                                            );
                                        })}
                                    </>
                                )}
                            </tbody>
                        </table>
                        {load ? (
                            <div className="text-center mt-4">
                                <ClipLoader size={40} loading={load} />
                            </div>
                        ) : (
                            <>{list.length === 0 && <div className="text-center mt-4">Nenhum dado encontrado</div>}</>
                        )}
                        {meta.total_pages > 1 && (
                            <Paginate
                                meta={meta}
                                setMeta={setMeta}
                                action={action}
                                setAction={setAction}
                                showDetails={true}
                            />
                        )}
                    </div>
                </Card>
            </div>
        </Layout>
    );
};

export default TransportPage;
